@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 75vw;
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;

    @include screen-lg {
        margin-top: 0;
        width: 600px;
        padding: 32px;
        flex-direction: column;
    }
}
