@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.leftContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-lg {
        width: 66.666vw;
    }

    & > div:last-child {
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include screen-lg {
            padding: 0 64px;
        }
    }
}

.cardsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;

    @include screen-lg {
        grid-template-columns: repeat(3, 1fr);
    }
}

.rightContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 32px 0;
    box-sizing: border-box;
    background: #ffffff;
    position: absolute;
    right: -100vw;
    z-index: 27;
    transition: right ease 250ms;

    @include screen-lg {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        position: initial;
        right: initial;
        width: 33.333vw;
    }
}

.rightContainerActive {
    right: 0;

    @include screen-lg {
        position: initial;
        right: initial;
    }
}

.navigation {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 30px;

    @include screen-lg {
        top: -40px;
    }
}

.button {
    color: "#000000";
    font-family: Raleway;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.085rem;
    cursor: pointer;
    background: none;
    border: none;
    margin: 0 8px;
}

.topLine {
    flex-shrink: 0;
    width: 140px;
    margin: 0;
    padding-top: 12px;
    border-top: solid 4px $primary-light-color;
}

.lineActive {
    border-top: solid 4px $primary-color;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include screen-lg {
        flex-direction: row;
    }
}

.leftContentContainer {
    width: 100%;
    flex-shrink: 0;

    @include screen-lg {
        width: 20%;
    }
}

.rightContentContainer {
    width: 100%;
    flex-shrink: 0;

    @include screen-lg {
        width: 76%;
    }
}

.categoryTitle {
    text-transform: none;
}

.redContainer {
    width: 100%;
    background: $primary-light-color;
    border: 1px solid $primary-color;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 12px 4px;

    @include screen-lg {
        flex-direction: row;
        padding: 8px 12px 4px;
        align-items: center;
    }
}

.preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // Keep 4:3 ratio
    width: calc(45vh * 0.75);
    height: 45vh;

    @include screen-lg {
        width: calc(40vh * 0.75);
        height: 40vh;
    }

    & > img {
        border-radius: 8px;
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

.addButton {
    background: $midnight-color;
    width: 59px;
    height: 59px;
    border-radius: 59px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen-lg {
        width: 72px;
        height: 72px;
        border-radius: 72px;
    }
}

.libraryIcon {
    width: 24px;
    height: 24px;

    @include screen-lg {
        width: 32px;
        height: 32px;
    }
}
