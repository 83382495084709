$background-color: #fffbf6;
$midnight-color: #151f28;
$black-color: rgba(0, 0, 0, 0.87);

$primary-color: #ee584b !default;
$on-primary-color: #ffffff !default;
$primary-light-color: #fdebe9 !default;
$grey-outline: #d0d4db !default;
$error-color: #c01d16 !default;
$success-color: #00d7a1 !default;
$disabled-color: #9e9e9e !default;
