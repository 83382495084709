@mixin screen-sm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin screen-md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin screen-lg {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin screen-xl {
    @media (min-width: 1200px) {
        @content;
    }
}
