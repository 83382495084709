@import "./styles//reset.scss";
@import "./styles/colors.scss";
@import "./styles/mixins.scss";
@import "./../node_modules/react-datepicker/dist/react-datepicker.min.css";
@import "./../node_modules/react-toastify/dist/ReactToastify.min.css";

html,
body {
  background-color: $background-color;
  color: $black-color;
  font-size: 11px;
  line-height: 12px;
  font-family: "Raleway", sans-serif;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  @include screen-lg {
    font-size: 12px;
    line-height: 13px;
  }

  // @include screen-lg {
  //   font-size: 12px;
  //   line-height: 13px;
  // }
}

a {
  color: $primary-color;
}

p {
  font-size: 1.325rem;
  line-height: 1.375rem;
}

h1 {
  font-size: 5.335rem;
  line-height: 6rem;
  font-weight: 800;
}

h2 {
  font-size: 4rem;
  line-height: 4.225rem;
  font-weight: 800;
}

h3 {
  font-size: 2.675rem;
  line-height: 3.5rem;
  font-weight: 800;
}

h4 {
  font-size: 2rem;
  line-height: 2.225rem;
  font-weight: 800;
}

h5 {
  font-size: 1.675rem;
  line-height: 1.875rem;
  font-weight: 800;
}

h6 {
  font-size: 1.335rem;
  line-height: 1.535rem;
  font-weight: 800;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: solid 1px #dfe4e8;
}

.react-datepicker__day-name {
  font-family: "Raleway", sans-serif;
  font-size: 1.167rem;
  line-height: 1.667rem;
  color: #626e88;
}

.react-datepicker__day-names {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: solid 1px #dfe4e8;
}

.react-datepicker__day {
  font-family: "Raleway", sans-serif;
  padding: 4px;
  font-size: 1.167rem;
  font-weight: normal;
  outline: none;

  @include screen-lg {
    padding: 8px;
  }
}

.react-datepicker__day:hover,
.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  color: $midnight-color !important;
  background-color: $grey-outline !important;
}

.react-datepicker__day--outside-month {
  color: $grey-outline;
}

.react-datepicker-time__header {
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  font-size: 1.333rem;
  line-height: 2rem;
  color: $midnight-color;
}

.react-datepicker__time-list-item {
  font-family: "Raleway", sans-serif;
  font-weight: normal !important;
  font-size: 1.167rem;
  line-height: 30px;
  color: $midnight-color;
  outline: none;
}

@keyframes copied-fade-out {
  0% {
    background-color: #ffffff;
  }

  10% {
    background-color: $success-color;
  }

  100% {
    background-color: #ffffff;
  }
}

@keyframes copied-label-fade-out {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
