@import "./../../../styles/mixins.scss";

.container {
    background: rgba(33, 43, 54, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    box-sizing: border-box;
}

.scrollable {
    padding: 32px 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    @include screen-lg {
        height: initial;
    }
}

.button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}
