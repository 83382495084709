@import "./../../../styles/mixins.scss";
@import "./../../../styles/colors.scss";

.container {
    user-select: none;
    flex-shrink: 0;
    min-width: 100px;
    padding: 0 16px;
    height: 48px;
    border-radius: 24px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
    background: #ffffff;
    box-sizing: border-box;
    z-index: 4;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include screen-lg {
        min-width: 200px;
    }
}

.button {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed !important;
}

.separator {
    width: 1px;
    height: 100%;
    background: $grey-outline;
    margin: 0 16px;
}

.section {
    width: calc(160px - 16px * 2 - 16px * 2 - 1px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-shrink: 0;

    @include screen-lg {
        width: calc(250px - 16px * 2 - 16px * 2 - 1px);
    }
}
