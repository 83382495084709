.container {
    padding: 32px 0;
    box-sizing: border-box;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > img {
        width: 24px;
        box-sizing: border-box;
        padding: 8px;
        transition: transform 250ms ease;
        margin-right: 12px;
    }
}

.content {
    transition: height 1000ms ease;
    max-height: 0;
    overflow: hidden;
}

.chevronOpen {
    transform: rotate(90deg);
}

.open {
    max-height: 10000px;
}
