@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.inputContainer {
    width: 100%;
    height: 48px;
    margin: 0 0 3px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    font-size: 18px;
    line-height: 2rem;
    font-family: "Raleway", sans-serif;
    font-weight: normal;

    @include screen-lg {
        font-size: 24px;
    }
}

.icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.input:focus {
    border: none;
    outline: none;
}

.label {
    color: $midnight-color;
    font-size: 1.165rem;
    position: absolute;
    top: calc(48px / 2 - 13px / 2);
    left: 10px;
    background: #ffffff;
    transition: top ease 250ms;
    pointer-events: none;
    padding: 0 6px;
}

.activeLabel {
    top: calc(13px / 2 * -1);
}

.errorLabel {
    color: $error-color;
}

.error {
    border-color: $error-color;
    border-width: 2px;
}

.errorText {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.125rem;
    color: $error-color;
}

.dropdownContainer {
    width: 100px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 8px;
    border: solid 1px $grey-outline;
    background: #ffffff;
}

.dropdownContainerAuto {
    width: initial !important;
}

.dropdownItem {
    width: 100px !important;
    box-sizing: border-box;
    padding: 12px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
}

.dropdownItemActive {
    background-color: $grey-outline;
}

.hidden {
    visibility: hidden;
    pointer-events: none;
}
