@import "./../../../styles/colors.scss";

.container {
    height: 48px;
    padding: 0 42px;
    border-radius: 24px;
    color: $on-primary-color;
    background-color: $primary-color;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-size: 1.083rem;
    line-height: 1.333rem;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.containerOutlined {
    height: 48px;
    padding: 0 42px;
    border-radius: 24px;
    color: #000000;
    background: #ffffff;
    border: solid 1px $grey-outline;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-size: 1.083rem;
    line-height: 1.333rem;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.containerText {
    height: 48px;
    padding: 0 42px;
    border-radius: 24px;
    color: $primary-color;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-size: 1.083rem;
    line-height: 1.333rem;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.disabled {
    cursor: not-allowed !important;
    background: $primary-light-color;
}

.disabledText {
    cursor: not-allowed !important;
    color: $primary-light-color;
}
