@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.dropdownContainer {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 8px;
    border: solid 1px $grey-outline;
    background: #ffffff;
}

.dropdownContainerAuto {
    width: initial !important;
}

.hidden {
    visibility: hidden;
    pointer-events: none;
}
