@import "./../../../styles/colors.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.checkbox {
    width: 14px;
    height: 14px;
    border: solid 2px rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    margin-right: 12px;
    flex-shrink: 0;
    cursor: pointer;
}

.active {
    border: solid 2px $primary-color;
    background: $primary-color;
}

.disabled {
    border: solid 2px transparent;
    background: rgba(0, 0, 0, 0.38);
}

.label {
    line-height: 20px;
    & > * {
        display: inline-block;
    }
}
