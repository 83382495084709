@import "./../../../styles/mixins.scss";

.cardsTable {
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;

    & tr {
        border-top: solid 1px #000000;

        & > td {
            padding: 12px 0;
        }
    }
}
