@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 32px);
    margin-top: 32px;
}

.leftContainer {
    width: 100vw;
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-lg {
        width: 66.666vw;
        height: 100%;
    }

    & > div:last-child {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include screen-lg {
            padding: 0 48px;
        }
    }
}

.rightContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 32px 0;
    box-sizing: border-box;
    background: #ffffff;
    position: fixed;
    right: -100vw;
    z-index: 5;
    transition: right ease 250ms;

    @include screen-lg {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        position: initial;
        right: initial;
        width: 33.333vw;
        height: 100%;
    }
}

.rightContainerActive {
    right: 0;

    @include screen-lg {
        position: initial;
        right: initial;
    }
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 0;

    @include screen-lg {
        padding: 32px;
    }
}

.pills {
    display: flex;
    flex-direction: row;
}

.pill {
    cursor: pointer;
    flex-grow: 1;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $grey-outline;

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.pillActive {
    background: $primary-light-color;
    border-color: $primary-color;

    & > div {
        color: $primary-color;
    }
}

.pillDisabled {
    cursor: not-allowed !important;
    background: #f0f0f0 !important;
    border-color: $grey-outline !important;

    & > div {
        color: $midnight-color;
    }
}

.separator {
    width: 100%;
    height: 1px;
    background: $grey-outline;
}

.optIn {
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
}

.cardContainer {
    width: 100vw;
    height: calc(650px * 0.75 + 10px);
    transition: transform ease 250ms;
    position: relative;
    overflow-x: scroll;

    @include screen-lg {
        overflow-x: initial;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
        width: 650px;
        height: calc(650px * 0.75);
    }

    & > img {
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

.cardContent {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    width: 650px;
    height: calc(650px * 0.75);

    @include screen-lg {
        background: initial;
        border-radius: initial;
        box-shadow: initial;
    }
}

.cardContainerCover {
    width: calc(45vh * 0.75);
    height: 45vh;
    transition: transform ease 250ms;
    position: relative;

    @include screen-lg {
        width: calc(55vh * 0.75);
        height: 55vh;
    }

    & > img,
    & > div > img {
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

.cardContainerCanvas {
    width: 650px;
    height: calc(650px * 0.75);
    background: #000000;
    opacity: 0.3;
    border-radius: 8px;
    position: absolute;
    left: 0;
    pointer-events: none;
    display: none;
}

.editorContainer {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
}

.dropdown {
    border: none;
    border-radius: 0;
}

.horizontalSeparator {
    margin: 0 12px;
    background: $grey-outline;
}

.textarea {
    border-radius: 8px;
    border: solid 1px $grey-outline;
    outline: none;
    padding: 12px;
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    font-size: 1.167rem;
    line-height: 1.333rem;
}

.cardVerticalLine {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background: $grey-outline;
}

.cardHorizontalLine {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background: $grey-outline;
    transition: opacity ease 250ms;
    opacity: 0;
}

.cardHidden {
    opacity: 1;
}

.headerButton {
    padding: 0 12px;
    background: transparent;
    color: $primary-color;
}

.greetingCardContainer {
    position: relative;
    width: calc(40vh * 0.75);
    height: 40vh;
}

.greetingCardIcon {
    position: absolute;
    top: -12px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: $success-color;
    padding: 4px;
    box-sizing: border-box;
}

.summaryEntry {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0;
}

.button {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: $midnight-color;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(45vh / 2 - 32px / 2);
    padding: 8px;
    box-sizing: border-box;

    @include screen-lg {
        width: 48px;
        height: 48px;
        border-radius: 48px;
        top: calc(55vh / 2 - 48px / 2);
    }

    & > img {
        width: 100%;
        height: 100%;
    }
}
