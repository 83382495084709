@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.inputContainer {
    width: 100%;
    height: 48px;
    margin: 0 0 3px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdownContainer {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 8px;
    border: solid 1px $grey-outline;
    background: #ffffff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.dropdownContainerAuto {
    width: initial !important;
}

.dropdownItem {
    width: calc(18px + 12px + 12px) !important;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 12px;
    cursor: pointer;

    @include screen-lg {
        width: calc(24px + 12px + 12px) !important;
    }

    &:hover {
        background-color: #f0f0f0;
    }
}

.dropdownItemActive {
    background-color: $grey-outline;
}

.hidden {
    visibility: hidden;
    pointer-events: none;
}

.color {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);
    border: solid 4px #ffffff;
    box-sizing: border-box;

    @include screen-lg {
        width: 24px;
        height: 24px;
        border-radius: 24px;
    }
}
