@import "./../../../styles/mixins.scss";

.container {
    padding: 32px 0 8px;
    box-sizing: border-box;

    @include screen-lg {
        padding: 32px 0;
    }
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > img {
        width: 24px;
        box-sizing: border-box;
        padding: 8px;
        transition: transform 250ms ease;
        margin-right: 12px;
    }
}

.content {
    transition: height 1000ms ease;
    max-height: 0;
    overflow: hidden;
}

.chevronOpen {
    transform: rotate(90deg);
}

.open {
    max-height: 10000px;
}

.contentContainer {
    box-sizing: border-box;
    width: calc(100vw - 16px * 2);
    z-index: 1;
    background: #ffffff;
    position: absolute;
    height: 60vh;
    overflow-y: scroll;
    max-height: 0;

    @include screen-lg {
        width: 100%;
        position: initial;
        border: none;
        padding: initial;
    }
}

.contentOpen {
    padding: 16px 16px 0;
    border: solid 1px #000000;
    max-height: 10000px;
}
