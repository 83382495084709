@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 75vw;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include screen-lg {
        margin-top: 0;
        width: 60vw;
        height: 80vh;
        flex-direction: row;
    }
}

.leftContainer {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    background: $midnight-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    @include screen-lg {
        padding: 0;
    }
}

.rightContainer {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 16px - 16px);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 16px;

    @include screen-lg {
        flex-basis: calc(50% - 50px - 50px);
        padding: 0 50px;
    }
}

.paragraph {
    font-size: 1.325rem;
    color: rgba(255, 255, 255, 0.87);
}

.h3 {
    text-align: center;
    padding: 16px 0;
    color: #ffffff;
}

.button {
    color: "#000000";
    font-family: Raleway;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.085rem;
    cursor: pointer;
    background: none;
    border: none;
    margin: 0 8px;
}

.ul {
    padding: 0 32px;
    margin: 16px 0;
    list-style: none;

    @include screen-lg {
        padding: 0 64px;
    }

    & > li {
        display: flex;
        flex-direction: row;
        padding: 4px 0;
    }

    & > li::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        font-size: 16px;
        color: $primary-color; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
        padding-right: 8px;
        text-align: center;
        align-self: center;
    }
}
