@import "./../../../styles/colors.scss";

.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px #00d7a1;
    border-radius: 3px;
    background-color: var(--white);
}

.containerOverlapping {
    border-color: $primary-color !important;
}

.containerModerate {
    border-color: #f7b500;
}

.disabled {
    cursor: default !important;
}

.disabledRotation {
    &:hover {
        opacity: 0 !important;
        cursor: default !important;
    }
}

.header {
    width: 70px;
    height: 15px;
    text-align: center;
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: solid 1px #00d7a1;
    background-color: #00d7a1;
    font-size: 0.667rem;
    line-height: 1rem;
    position: absolute;
    top: -8px;
    z-index: 1;
    user-select: none;
    pointer-events: none;
}

.headerModerate {
    color: rgba(0, 0, 0, 0.87);
    border-color: #f7b500;
    background-color: #f7b500;
}

.removeButton {
    width: 15px;
    height: 15px;
    border-radius: 16px;
    background: #00d7a1;
    position: absolute;
    top: -8px;
    right: 12px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-size: 8px;
    line-height: 8px;
    cursor: pointer;
}

.removeButtonOverlapping {
    background: $primary-color !important;
}

.removeButtonModerate {
    background: #f7b500;
}

.headerOverlapping {
    color: #ffffff;
    border: solid 1px $primary-color;
    background-color: $primary-color;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    // min-width: 150px;
    background-color: var(--white);
    position: relative;
    box-sizing: border-box;
    // max-width: calc(650px / 2 - 2px);
    // max-height: calc(650px * 0.75 - 10px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    & > pre {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        pointer-events: none;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(650px / 2 - 2px);
        max-height: calc(650px * 0.75 - 10px - 12px - 8px);
    }
}

.contentOverlapping {
    border: solid 1px $primary-color;
}

.rotationTopRight {
    position: absolute;
    top: -13px;
    right: -13px;
    width: 20px;
    height: 20px;
    background: url("./../../../img/ic-rotate-top-right.png") center center no-repeat;
    background-size: 16px 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    transition: opacity ease 250ms;
    opacity: 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    & > div {
        width: 3px;
        height: 3px;
        border: solid 1px #00d7a1;
        background-color: #00d7a1;
    }
}

.rotationBottomRight {
    position: absolute;
    bottom: -13px;
    right: -13px;
    width: 20px;
    height: 20px;
    background: url("./../../../img/ic-rotate-bottom-right.png") center center no-repeat;
    background-size: 16px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    opacity: 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    & > div {
        width: 3px;
        height: 3px;
        border: solid 1px #00d7a1;
        background-color: #00d7a1;
    }
}

.rotationBottomLeft {
    position: absolute;
    bottom: -13px;
    left: -13px;
    width: 20px;
    height: 20px;
    background: url("./../../../img/ic-rotate-bottom-left.png") center center no-repeat;
    background-size: 16px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    opacity: 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    & > div {
        width: 3px;
        height: 3px;
        border: solid 1px #00d7a1;
        background-color: #00d7a1;
    }
}

.rotationTopLeft {
    position: absolute;
    top: -13px;
    left: -13px;
    width: 20px;
    height: 20px;
    background: url("./../../../img/ic-rotate-top-left.png") center center no-repeat;
    background-size: 16px 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    opacity: 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    & > div {
        width: 3px;
        height: 3px;
        border: solid 1px #00d7a1;
        background-color: #00d7a1;
    }
}

.resizingOverlapping {
    border-color: $primary-color !important;
}

.resizingTopRight {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 9px;
    height: 9px;
    border: solid 1px #00d7a1;
    background: #ffffff;
    transition: opacity ease 250ms;
    box-sizing: content-box;
    z-index: 1;
    pointer-events: none;
    touch-action: none;
    // opacity: 0;

    &:hover {
        opacity: 1;
    }
}

.resizingBottomRight {
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 9px;
    height: 9px;
    border: solid 1px #00d7a1;
    background: #ffffff;
    transition: opacity ease 250ms;
    box-sizing: content-box;
    z-index: 1;
    pointer-events: none;
    touch-action: none;
    // opacity: 0;

    &:hover {
        opacity: 1;
    }
}

.resizingBottomLeft {
    position: absolute;
    bottom: -5px;
    left: -5px;
    width: 9px;
    height: 9px;
    border: solid 1px #00d7a1;
    background: #ffffff;
    transition: opacity ease 250ms;
    box-sizing: content-box;
    z-index: 1;
    pointer-events: none;
    touch-action: none;
    // opacity: 0;

    &:hover {
        opacity: 1;
    }
}

.resizingTopLeft {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 9px;
    height: 9px;
    border: solid 1px #00d7a1;
    background: #ffffff;
    transition: opacity ease 250ms;
    box-sizing: content-box;
    z-index: 1;
    pointer-events: none;
    touch-action: none;
    // opacity: 0;

    &:hover {
        opacity: 1;
    }
}

.resizingModerate {
    border-color: #f7b500;
}

.mediaHorizontal {
    flex-direction: column;
    align-items: center;
}

.mediaVertical {
    flex-direction: row;
}
