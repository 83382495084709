@import "./../../../styles/colors.scss";

.body1 {
    font-family: "Raleway", sans-serif;
    font-size: 1.333rem;
    line-height: 2rem;
    color: $midnight-color;
}

.body2 {
    font-family: "Raleway", sans-serif;
    font-size: 1.167rem;
    line-height: 1.667rem;
    color: $midnight-color;
}

.button {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-size: 1.083rem;
    line-height: 1.333rem;
    font-weight: 800;
    color: $midnight-color;
}

.caption {
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    line-height: 1.333rem;
    font-weight: normal;
    color: rgba(18, 18, 18, 0.87);
}

.overline {
    font-family: "Raleway", sans-serif;
    font-size: 0.833rem;
    line-height: 1rem;
    font-weight: 600;
    color: $midnight-color;
}

.h1 {
    font-family: "Raleway", sans-serif;
    font-size: 5.333rem;
    line-height: 6rem;
    font-weight: 800;
    color: $midnight-color;
}

.h2 {
    font-family: "Raleway", sans-serif;
    font-size: 4rem;
    line-height: 4.667rem;
    font-weight: 800;
    color: $midnight-color;
}

.h3 {
    font-family: "Raleway", sans-serif;
    font-size: 2.333rem;
    line-height: 3.167rem;
    font-weight: 800;
    color: $midnight-color;
}

.h4 {
    font-family: "Raleway", sans-serif;
    font-size: 2rem;
    line-height: 2.667rem;
    font-weight: 800;
    color: $midnight-color;
}

.h5 {
    font-family: "Raleway", sans-serif;
    font-size: 1.667rem;
    line-height: 2rem;
    font-weight: 800;
    color: $midnight-color;
}

.h6 {
    font-family: "Raleway", sans-serif;
    font-size: 1.333rem;
    line-height: 2rem;
    font-weight: 800;
    color: $midnight-color;
}
