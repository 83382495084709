.container {
    width: 24px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #000000;
}
