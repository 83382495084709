@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 32px);
    margin-top: 32px;
}

.leftContainer {
    width: 100vw;
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-lg {
        width: 66.666vw;
        height: 100%;
    }

    & > div:last-child {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include screen-lg {
            padding: 0 48px;
        }
    }
}

.rightContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 32px 0;
    box-sizing: border-box;
    background: #ffffff;
    position: absolute;
    right: -100vw;
    z-index: 5;
    transition: right ease 250ms;

    @include screen-lg {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        position: initial;
        right: initial;
        width: 33.333vw;
        height: 100%;
    }
}

.rightContainerActive {
    right: 0;

    @include screen-lg {
        position: initial;
        right: initial;
    }
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 0;

    @include screen-lg {
        padding: 32px;
    }
}

.pills {
    display: flex;
    flex-direction: row;
}

.pill {
    cursor: pointer;
    flex-grow: 1;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $grey-outline;

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.pillActive {
    background: $primary-light-color;
    border-color: $primary-color;

    & > div {
        color: $primary-color;
    }
}

.pillDisabled {
    cursor: not-allowed !important;
    background: #f0f0f0 !important;
    border-color: $grey-outline !important;

    & > div {
        color: $midnight-color;
    }
}

.separator {
    width: 100%;
    height: 1px;
    background: $grey-outline;
}

.cardContainer {
    width: calc(18vh * 0.75);
    height: 18vh;
    transition: transform ease 250ms;
    margin-bottom: 24px;
    
    @include screen-lg {
        width: calc(20vh * 0.75);
        height: 20vh;
        margin-bottom: 12px;
    }

    & > img {
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

.modalContainer {
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;

    @include screen-lg {
        width: 40vw;
    }
}
