@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @include screen-lg {
        height: 100%;
    }
}

.backgroundLeft {
    position: absolute;
    left: 0;
    top: -9vh;
    width: 50vw;
    height: 110vh;
    pointer-events: none;

    @include screen-lg {
        background: url("./../../img/background-left.svg") right center no-repeat;
        background-size: cover;
    }
}

.backgroundRight {
    position: absolute;
    right: 0;
    bottom: -9vh;
    width: 200px;
    height: 300px;
    pointer-events: none;

    @include screen-lg {
        background: url("./../../img/background-right.svg") left top no-repeat;
        background-size: cover;
    }
}

.mainContainer {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    flex-grow: 1;

    @include screen-lg {
        flex-direction: row;
        flex-flow: row;
    }
}

.rightContainer {
    flex-grow: 1;
    // flex-basis: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    z-index: 1;

    @include screen-lg {
        padding: calc(64px + 16px) 0 0 0;
        flex-basis: 35%;
        padding: 64px 32px;
    }
}

.carouselWrapper {
    border-radius: 24px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
    width: 100vw;
    height: 600px;
    box-sizing: border-box;
    background: #ffffff;
    padding: 0 15px 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @include screen-lg {
        width: 90%;
        margin-top: 0;
        //margin-left: 80px;
        height: 60vh;
        padding: 30px;
    }
}

.carouselContainer {
    width: 100%;
    height: 100%;
    min-height: 40px;
    position: relative;
    // overflow: hidden;
    // overflow-y: scroll;
}

.carouselItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity ease-in-out 300ms;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include screen-lg {
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    & > div:first-child {
        width: 100%;

        @include screen-lg {
            width: 55%;
        }

        & > img {
            width: 100%;

            @include screen-lg {
                width: calc(100% + 100px);
                position: relative;
                left: -90px;
            }
        }
    }

    & > div:last-child {
        width: 100%;

        @include screen-lg {
            width: 40%;
        }
    }
}

.active {
    opacity: 1;
}

.carouselControls {
    margin: 12px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 5;
}

.carouselDot {
    margin: 0 4px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: $primary-light-color;
    cursor: pointer;
}

.activeDot {
    background: $primary-color;
}

.carouselArrow {
    cursor: pointer;
    padding: 0;
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 5;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.carouselArrowLeft {
    left: 16px;
}

.carouselArrowRight {
    right: 16px;
}

.button {
    position: relative;

    &:after {
        content: "";
        // display: block;
        display: none;
        position: absolute;
        bottom: 12px;
        width: calc(100% - 42px * 2);
        height: 2px;
        background: $primary-color;
    }
}
