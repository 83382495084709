@import "./../../../styles/colors.scss";

.container {
    position: absolute;
    top: 16px;
    width: calc(100% - 16px - 16px);
    height: 64px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
}

.button {
    color: "#000000";
    font-family: Raleway;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.085rem;
    cursor: pointer;
    background: none;
    border: none;
    margin: 0 8px;
}
