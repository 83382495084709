@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.active {
    background: #ffffff;
}

.cardContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // Keep 4:3 ratio
    width: calc(72px * 0.75);
    height: 72px;

    @include screen-lg {
        width: calc(72px * 0.75);
        height: 72px;
    }

    & > img:last-child {
        border-radius: 8px;
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

.title {
    text-transform: none;
}

.button {
    color: "#000000";
    font-family: Raleway;
    font-weight: bold;
    font-size: 1.085rem;
    cursor: pointer;
    background: none;
    border: none;
    margin: 0 8px;
    height: 24px;
    text-transform: none;
}

.smallContainer {
    display: flex;
    flex-direction: row;
}

.contentContainer {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cardIcon {
    position: absolute;
    top: -6px;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background: $success-color;
    padding: 4px;
    box-sizing: border-box;
}
