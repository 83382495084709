@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 75vw;
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    padding: 32px;
    box-sizing: border-box;

    @include screen-lg {
        margin-top: 0;
        width: 400px;
        min-height: 350px;
        flex-direction: column;
    }
}

.scrollable {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    @include screen-lg {
        height: initial;
    }
}
