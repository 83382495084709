@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 75vw;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include screen-lg {
        margin-top: 0;
        width: 60vw;
        height: 80vh;
        flex-direction: row;
    }
}

.leftContainer {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    background: $midnight-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    @include screen-lg {
        padding: 0;
    }
}

.rightContainer {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 16px - 16px);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 16px;

    @include screen-lg {
        flex-basis: calc(50% - 50px - 50px);
        padding: 0 50px;
    }
}
