.container {
    width: 155px;
    height: 20px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text {
    text-transform: uppercase;
    margin: 0 8px;
}

.dashedLine {
    width: 100%;
    border-bottom: dashed 1px #000000;
}
