@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: #ffffff;
}

.dropdownContainer {
    width: 200px;
    max-height: 200px;
    border-radius: 8px;
    border: solid 1px $grey-outline;
    background: #ffffff;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.section {
    width: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 12px;
}

.list {
    width: 45px;
    height: 75px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px;
    border: solid 1px $grey-outline;
    overflow: hidden;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    &:before {
        display: block;
        content: "";
        width: 43px;
        height: 24px;
        position: fixed;
        top: 33px;
        transform: translateX(-4px);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0) 600%);
        pointer-events: none;
    }

    &:after {
        display: block;
        content: "";
        width: 43px;
        height: 24px;
        position: fixed;
        top: 83px;
        transform: translateX(-4px);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0) 600%);
        pointer-events: none;
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > div {
            scroll-snap-align: start;
        }
    }
}

.controlIcon {
    width: 14px;
    height: 24px;
    cursor: pointer;
}

.dropdownContainerAuto {
    width: initial !important;
}

.dropdownItem {
    width: 60px !important;
    text-align: center;
    box-sizing: border-box;
    padding: 12px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
}

.dropdownItemActive {
    background-color: $grey-outline;
}

.hidden {
    visibility: hidden;
    pointer-events: none;
}

.disabled {
    cursor: not-allowed;
}

.enabled {
    cursor: pointer;
}
