@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: solid 1px transparent;
    cursor: pointer;
    position: relative;

    @include screen-lg {
        padding: 40px;
    }
}

.active {
    border: solid 1px $primary-color;
    background: $primary-light-color;
}

.preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // Keep 4:3 ratio
    width: calc(45vh * 0.75);
    height: 45vh;

    @include screen-lg {
        width: calc(40vh * 0.75);
        height: 40vh;
    }

    & > img {
        border-radius: 8px;
        border: dashed 2px $grey-outline;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

.uploaded {
    border-color: transparent !important;
}

.addButton {
    background: $midnight-color;
    width: 90px;
    height: 90px;
    border-radius: 90px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15%;

    @include screen-lg {
        top: 20%;
    }
}

.uploadBar {
    position: absolute;
    top: 10%;

    @include screen-lg {
        top: 20%;
    }
}

.libraryIcon {
    width: 32px;
    height: 32px;

    @include screen-lg {
        width: 43px;
        height: 43px;
    }
}

.addIcon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 18px;
    right: 18px;

    @include screen-lg {
        width: 29px;
        height: 29px;
        top: 8px;
        right: 8px;
    }
}

.captionText {
    text-align: center;
    position: absolute;
    top: calc(20% + 90px);

    @include screen-lg {
        top: calc(25% + 107px);
    }
}

.button {
    width: 180px;
    padding: 0 16px;
    position: absolute;
    bottom: 16px;

    @include screen-lg {
        bottom: 32px;
    }
}
