@import "./../../../styles/colors.scss";

.container {
    width: 100%;
    height: 72px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
    display: flex;
    position: relative;
    align-items: center;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        background: $primary-light-color;
        left: 0;
        bottom: 0;
    }

    &:after {
        transition: width ease 100ms;
        content: "";
        position: absolute;
        width: 0;
        height: 4px;
        background: $primary-color;
        left: 0;
        bottom: 0;
    }
}

.idle {
    &:before {
        display: none !important;
    }

    &:after {
        display: none !important;
    }
}

@for $i from 0 through 100 {
    .progress-#{$i} {
        &:after {
            width: #{$i} + "%";
        }
    }
}
