@import "./../../../styles/colors.scss";

.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px transparent;
    border-radius: 3px;
    background-color: var(--white);
}

.containerVisible {
    border-color: #f7b500;
}

.header {
    width: 70px;
    height: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: solid 1px #f7b500;
    background-color: #f7b500;
    font-size: 0.667rem;
    line-height: 1rem;
    position: absolute;
    top: -8px;
    z-index: 1;
    user-select: none;
    pointer-events: none;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--white);
    position: relative;
    box-sizing: border-box;
    max-width: calc(650px / 2 - 2px);
    max-height: calc(650px * 0.75 - 10px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    & > pre {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        pointer-events: none;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(650px / 2 - 2px);
        max-height: calc(650px * 0.75 - 10px - 12px - 8px);
    }
}

.mediaHorizontal {
    flex-direction: column;
    align-items: center;
}

.mediaVertical {
    flex-direction: row;
}
