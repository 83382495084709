@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    position: absolute;
    top: 0;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $midnight-color;
    width: 100vw;
    height: 32px;
}
