@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    z-index: 100;
}

.leftContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-lg {
        width: 66.666vw;
    }

    & > div:last-child {
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include screen-lg {
            padding: 0 64px;
        }
    }
}

.rightContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    box-sizing: border-box;
    background: #ffffff;
    position: absolute;
    right: -100vw;
    z-index: 20;
    transition: right ease 250ms;

    @include screen-lg {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        position: initial;
        right: initial;
        width: 33.333vw;
    }
}

.cardContainer {
    width: 100vw;
    height: calc(650px * 0.75 + 10px);
    transition: transform ease 250ms;
    position: relative;
    overflow-x: scroll;

    @include screen-lg {
        overflow-x: initial;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
        width: 650px;
        height: calc(650px * 0.75);
    }

    & > img {
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

.cardContent {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    width: 650px;
    height: calc(650px * 0.75);

    @include screen-lg {
        background: initial;
        border-radius: initial;
        box-shadow: initial;
    }
}

.cardContainerCover {
    width: calc(45vh * 0.75);
    height: 45vh;
    transition: transform ease 250ms;
    position: relative;

    @include screen-lg {
        width: calc(55vh * 0.75);
        height: 55vh;
    }

    & > img,
    & > div > img {
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

.button {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: $midnight-color;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(45vh / 2 - 32px / 2);
    padding: 8px;
    box-sizing: border-box;

    @include screen-lg {
        width: 48px;
        height: 48px;
        border-radius: 48px;
        top: calc(55vh / 2 - 48px / 2);
    }

    & > img {
        width: 100%;
        height: 100%;
    }
}

.cardVerticalLine {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background: $grey-outline;
}

.cardHorizontalLine {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background: $grey-outline;
    transition: opacity ease 250ms;
    opacity: 0;
}
