@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.leftContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-lg {
        width: 66.666vw;
    }

    & > div:last-child {
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include screen-lg {
            padding: 0 64px;
        }
    }
}

.rightContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px 32px;
    box-sizing: border-box;
    background: #ffffff;
    position: absolute;
    right: -100vw;
    z-index: 20;
    transition: right ease 250ms;

    @include screen-lg {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        position: initial;
        right: initial;
        width: 33.333vw;
        padding: 32px;
    }
}

.rightContainerActive {
    right: 0;

    @include screen-lg {
        position: initial;
        right: initial;
    }
}

.contentContainer {
    overflow: hidden;
    overflow-x: scroll;
    max-width: 100vw;

    & > div {
        display: flex;
        flex-direction: row;
    }

    @include screen-lg {
        max-width: 100%;
    }
}

.redContainer {
    width: calc(100% - 8px * 2);
    background: $primary-light-color;
    border: 1px solid $primary-color;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 12px 4px;

    @include screen-lg {
        text-align: start;
        align-items: flex-start;
        flex-direction: row;
        padding: 8px 12px 4px;
        align-items: center;
    }
}

.cardPackActive {
    border: solid 1px $primary-color !important;
}

.cardPackContainer {
    position: relative;
    border: solid 1px #fdebe9;
    width: 60vw;
    background: #fdebe9;
    border-radius: 8px;
    margin: 0 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @include screen-lg {
        width: 200px;
    }
}

.cardPackAmount {
    font-family: "Raleway", sans-serif;
    font-size: 7.75rem;
    line-height: 7.75rem;
    font-weight: 600;
    color: $midnight-color;
}

.cardPackSaveContainer {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    color: #ffffff;
    background: #151f28;
    font-size: 0.963rem;
    line-height: 1.15rem;
    width: 100px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.summaryEntry {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0;
}

.activeTick {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: $primary-color;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 3px;
    box-sizing: border-box;
}
