@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;

    // @include screen-lg {
    //     background-image: url("../../img/il_home_background.svg");
    //     background-position: -90px 60vh;
    //     background-repeat: no-repeat;
    //     background-size: contain;
    // }
}

.backgroundLeft {
    position: absolute;
    left: 0;
    top: -9vh;
    width: 300px;
    height: 110vh;
    pointer-events: none;

    @include screen-lg {
        background: url("./../../img/background-left.svg") right center no-repeat;
        background-size: cover;
    }
}

.backgroundRight {
    position: absolute;
    right: 0;
    bottom: -9vh;
    width: 176px;
    height: 227px;
    pointer-events: none;

    @include screen-lg {
        background: url("./../../img/background-right.svg") left top no-repeat;
        background-size: cover;
    }
}

.cardPreviewContainer {
    position: relative;
    width: calc(30vh * 0.75);
    height: 30vh;
    background-color: var(--white);

    @include screen-lg {
        width: calc(40vh * 0.75);
        height: 40vh;
    }

    & > img:first-child {
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

.previewIcon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: $success-color;
    padding: 4px;
    box-sizing: border-box;
    position: absolute;
    top: -12px;
    right: -12px;
}
