.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px #000000;
}

.title {
    text-transform: none;
    margin-bottom: 16px;
}
