@import "./../../../styles/colors.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inputContainer {
    width: 100%;
    margin: 0 0 3px;
    border-radius: 8px;
    border: solid 1px $grey-outline;
    position: relative;
}

.input {
    width: calc(100% - 16px - 16px);
    height: 100%;
    background: transparent;
    padding: 12px 16px;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 1.167rem;
    line-height: 2rem;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
}

.input:focus {
    border: none;
    outline: none;
}

.label {
    color: $midnight-color;
    font-size: 1.165rem;
    position: absolute;
    top: calc(48px / 2 - 13px / 2);
    left: 10px;
    background: #ffffff;
    transition: top ease 250ms;
    pointer-events: none;
    padding: 0 6px;
}

.activeLabel {
    top: calc(13px / 2 * -1);
}

.errorLabel {
    color: $error-color;
}

.error {
    border-color: $error-color;
    border-width: 2px;
}

.errorText {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.125rem;
    color: $error-color;
}

.disabled {
    cursor: not-allowed;
}
