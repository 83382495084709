@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    width: 75vw;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include screen-lg {
        margin-top: 0;
        width: 60vw;
        height: 80vh;
        flex-direction: row;
    }
}

.leftContainer {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    background: $midnight-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    @include screen-lg {
        padding: 0;
    }
}

.rightContainer {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 16px - 16px);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // overflow-y: scroll;

    & > div {
        padding: 32px 16px 16px;
    }

    @include screen-lg {
        flex-basis: calc(50% - 50px - 50px);

        & > div {
            padding: 0 50px;
            // min-height: 555px;
        }
    }
}

.scrollable {
    padding: 32px 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    @include screen-lg {
        height: initial;
    }
}

.paragraph {
    font-size: 1.325rem;
    color: rgba(255, 255, 255, 0.87);
}

.h3 {
    text-align: center;
    padding: 16px 0;
    color: #ffffff;
}

.button {
    color: "#000000";
    font-family: Raleway;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.085rem;
    cursor: pointer;
    background: none;
    border: none;
    margin: 0 8px;
}
