@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.leftContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-lg {
        width: 66.666vw;
    }

    & > div:last-child {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include screen-lg {
            padding: 0 48px;
        }
    }
}

.rightContainer {
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: #ffffff;
    position: absolute;
    right: -100vw;
    z-index: 5;
    transition: right ease 250ms;
    padding: 0 32px;

    @include screen-lg {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        position: initial;
        right: initial;
        width: 33.333vw;
    }
}

.rightContainerActive {
    right: 0;

    @include screen-lg {
        position: initial;
        right: initial;
    }
}

.cardContainer {
    width: calc(45vh * 0.75);
    height: 45vh;
    transition: transform ease 250ms;

    @include screen-lg {
        width: calc(55vh * 0.75);
        height: 55vh;
    }

    & > img {
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

.invitationCardContainer {
    position: relative;
    width: calc(40vh * 0.75);
    height: 40vh;
}