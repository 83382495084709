@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: solid 1px transparent;
    cursor: pointer;
    position: relative;

    @include screen-lg {
        padding: 20px 1vw;
    }
}

.active {
    border: solid 1px $primary-color;
    background: $primary-light-color;
}

.preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // Keep 4:3 ratio
    width: calc(45vw * 0.75);
    height: 45vw;

    @include screen-lg {
        width: calc(15vw * 0.75);
        max-width: calc(200px * 0.75);
        height: 15vw;
        max-height: 200px;
    }

    & > img {
        border-radius: 8px;
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

.addButton {
    background: $midnight-color;
    width: 59px;
    height: 59px;
    border-radius: 59px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.libraryIcon {
    width: 24px;
    height: 24px;
}

.addIcon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 8px;
    right: 8px;
}
