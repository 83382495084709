@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.container {
    background: $midnight-color;
    width: calc(100% - 16px - 16px);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
}

.headerContainer {
    height: 64px;
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #ffffff;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
}

.button {
    color: #ffffff;
    font-family: Raleway;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.085rem;
    line-height: 1.285rem;
    cursor: pointer;
    background: none;
    border: none;
    margin: 0 8px;
    display: flex;
    align-items: center;
    padding: 0 0 8px 0;
    position: relative;

    &:after {
        transition: width ease 250ms;
        display: block;
        content: "";
        width: 0;
        height: 2px;
        background: $primary-color;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.navigation {
    width: 100%;
    height: 40px;
    overflow: hidden;
    overflow-x: auto;
    flex-shrink: 0;
}

.navigationContainer {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    @include screen-lg {
        justify-content: center;
    }
}

.navigationButton {
    margin: 0 12px;
    flex-shrink: 0;
}

.active {
    &:after {
        width: 100%;
    }
}

.heading {
    color: #ffffff;
    font-size: 2.335rem;
    line-height: 2.535rem;
    font-weight: 800;
}

.paragraph {
    color: #ffffff;
    font-size: 1.165rem;

    @include screen-lg {
        margin: 0 25vw;
    }
}
