@import "./../../../styles/colors.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
}

.tab {
    flex-grow: 1;
    padding: 12px 12px;
    position: relative;
    flex-grow: 1;
    cursor: pointer;
    text-align: center;

    &:before {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-light-color;
    }

    &:after {
        content: "";
        display: block;
        height: 4px;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-color;
    }
}

.active {
    &:after {
        transition: width ease 250ms;
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-color;
    }
}

.glow {
    &:after {
        transition: none;
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-color;
    }
}
