@import "./../../../styles/colors.scss";

.container {
    min-width: 260px;
    padding: 12px;
    border-radius: 4px;
    border: solid 1px $grey-outline;
    background-color: #ffffff;
}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 4px;
    border: solid 1px $grey-outline;
    background-color: #ffffff;
}

.input {
    margin: 0 16px;
    flex-grow: 1;
    border: none;
    outline: none;
    font-family: "Raleway", sans-serif;
    font-size: 1.167rem;
    line-height: 1.667rem;
    color: $midnight-color;
    font-weight: normal;
}

.icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: $primary-color;
    margin: 0 16px;
}

.iconDisabled {
    cursor: not-allowed !important;
    background: $primary-light-color !important;
}

.tabbar {
    display: flex;
    flex-direction: row;
}

.item {
    cursor: pointer;
    padding: 12px 0;

    &:first-child {
        margin-right: 16px;
    }
}

.itemActive {
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: $midnight-color;
        position: relative;
        top: 4px;
    }
}

.entryContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8px 0;
}

.removeIcon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: solid 1px $grey-outline;
}

.previewIcon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: $grey-outline;
    padding: 4px;
    box-sizing: border-box;
}

.previewIconActive {
    background: $success-color !important;
}

.errorText {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.125rem;
    color: $error-color;
}
