@import "./../../../styles/mixins.scss";
@import "./../../../styles/colors.scss";

.container {
    width: 160px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 26 !important;

    @include screen-lg {
        width: 260px;
    }
}

.tab {
    padding: 12px 4px;
    flex-grow: 1;
    flex-shrink: 0;
    cursor: pointer;
    text-align: center;
    width: 80px;
    box-sizing: border-box;

    @include screen-lg {
        padding: 12px 12px;
        width: 130px;
    }

    &:before {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-light-color;
    }

    &:after {
        transition: left ease 250ms;
        content: "";
        display: block;
        height: 4px;
        width: 80px;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-color;

        @include screen-lg {
            width: 130px;
        }
    }
}

.tab-full {
    padding: 12px 12px;
    flex-grow: 1;
    flex-shrink: 0;
    cursor: pointer;
    text-align: center;
    width: calc(100% / 3);
    box-sizing: border-box;

    &:before {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-light-color;
    }

    &:after {
        transition: left ease 250ms;
        content: "";
        display: block;
        height: 4px;
        width: calc(100% / 3);
        position: absolute;
        top: 0;
        left: 0;
        background: $primary-color;
    }
}

.active-0 {
    &:after {
        left: 0;
    }
}

.active-1 {
    &:after {
        left: 80px;

        @include screen-lg {
            left: 130px;
        }
    }
}

.active-full-0 {
    &:after {
        left: 0;
    }
}

.active-full-1 {
    &:after {
        left: calc(100% / 3);
    }
}

.active-full-2 {
    &:after {
        left: calc(100% / 3 * 2);
    }
}
